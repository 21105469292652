var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c1747e0dbcc8f8904c866bc6d088201ebdf7000b"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

export const sentryDefaults = {
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV || "development",
  dsn:
    process.env.NEXT_PUBLIC_SENTRY_DSN ||
    "https://cf7b7875496e42cb745179062ffc0449@o1254013.ingest.sentry.io/4506622229020672",
  tracesSampleRate: 1.0,
};

Sentry.init(sentryDefaults);
